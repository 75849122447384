<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-options="productOptions"
      :filter-names="filterNames"
      search-label="search"
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:operational_status="{ item }">
        <div> {{ (item.operational_status) ? 'Active' : 'Inactive' }}</div>
      </template>
      <template v-slot:services="{ item }">
        <div class="text-capitalize">
          {{ item.services }}
        </div>
      </template>

      <template v-slot:name="{ item }">
        <div class="text-capitalize">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- Action Modals -->
      <template #actionModals>
        <div class="d-flex">
          <create-modal
            v-if="isCreate"
            :filter-options="options"
          />
          <edit-modal
            v-if="(isView || isEdit) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>

    <!-- <section>
      <locations-map />
    </section> -->
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateModal: () => import("./dialogs/CreateTerminals.vue"),
    EditModal: () => import("./dialogs/EditTerminals.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      permissions: {
        view: "terminals.view",
        create: "terminals.create",
        edit: "terminals.edit",
        delete: "terminals.delete",
      },
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "fleet", value: "Fleet" },
      ],
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "terminal/getLoading",
      meta: "terminal/getMeta",
      list: "terminal/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        ...(this.authUser.user_nature == "fleet"
          ? [
            {
              text: this.$t("manufacturer"),
              align: "left",
              sortable: false,
              value: "tenant_name",
            },
          ]
          : []),

        {
          text: this.$t("capacity"),
          align: "left",
          sortable: false,
          value: "capacity",
        },
        // {
        //   text: this.$t("services"),
        //   align: "left",
        //   sortable: false,
        //   value: "services",
        // },
        {
          text: this.$t("address"),
          align: "left",
          sortable: false,
          value: "address",
        },
        // {
        //   text: this.$t("country"),
        //   align: "left",
        //   sortable: false,
        //   value: "country_name",
        // },
        // {
        //   text: this.$t("state"),
        //   align: "left",
        //   sortable: false,
        //   value: "state_name",
        // },
        {
          text: this.$t("city"),
          align: "left",
          sortable: false,
          value: "city_name",
        },

        {
          text: this.$t("contact_person"),
          align: "left",
          sortable: false,
          value: "contact_1_name",
        },
        {
          text: this.$t("phone_number"),
          align: "left",
          sortable: false,
          value: "contact_1",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "operational_status",
        },
        ...(this.isView || this.isEdit || this.isDelete
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return ["country", "state", "city", "status"];
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('terminal/setLoadingValue', true)
  },
  beforeDestroy() {
    const params = {
      name: "terminal",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      this.options.page = 1;
      this.options.operational_status = (this.options.status != null) ? (this.options.status == 'active' ? true : false) : ''
      await this.$store
        .dispatch("terminal/list", this.options)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("terminal/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

  },
};
</script>
<style lang="sass" scoped>
.map-button
  height: 41px !important
  margin-right: 5px

.btn-disabled
  pointer-events: none !important
  background-color: #8f8f8f !important

:deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
  font-size: 0.875rem !important
</style>